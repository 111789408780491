import Vue from "vue";
import cajerosModule from "@/store/modules/cajerosModule";
import { mdiPencilCircle } from "@mdi/js";

export default Vue.extend({
  name: "InfoGenComponent",
  props: {
    cajero: {
      type: Object,
      required: true,
    },
    efectivo: {
      type: Boolean,
      required: true,
    },
    idKiosko: {
      type: String,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      switch1: this.efectivo,
      loading: true as boolean,
      kiosko: this.idKiosko,
      icons: { edit: mdiPencilCircle } as Record<string, string>,
      isEditing: false,
      rules: {
        required: (value: string[]) => !!value || "Required.",
      },
    };
  },

  methods: {
    desactivarCajero() {
      return cajerosModule.desactivarEfectivo(this.kiosko).then(() => {
        this.switch1 = false;
        //this.cajero.aceptaEfectivo = false;
      });
    },
    activarCajero() {
      return cajerosModule.activarEfectivo(this.kiosko).then(() => {
        this.switch1 = true;
        //this.cajero.aceptaEfectivo = true;
      });
    },
  },
});
